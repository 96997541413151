import {
  AdminService as TSAdminService,
  BnamesService as TSBnamesService,
  DefaultService as TSDefaultService,
  DigitalStoresService as TSDigitalStoresService,
  ModuleService as TSModuleService,
  NotificationsService as TSNotificationsService,
  OwnersService as TSOwnersService,
  ReportsService as TSReportsService,
  StoreAvailabilityService as TSStoreAvailabilityService,
  UsersService as TSUsersService
} from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService'
import { captureException } from '@sentry/react'
import { get } from 'lodash'
import posthog from 'posthog-js'
import { sendSlackNotification } from 'src/utils/api'
import {
  blockedApiEndpointsForSandbox,
  CYPRESS_APP_EMAIL,
  CYPRESS_LOGIN_EMAIL,
  CYPRESS_REGISTER_EMAIL,
  CYPRESS_USER_MANAGEMENT_EMAIL
} from 'src/utils/config/config'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { addTableIdToRequest, updateTableId } from 'src/utils/functions/openApiSupport'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'
import { getTokenCreationFromStorage, getTokenExpireFromStorage } from './firebase'

const restrictedEmails = [CYPRESS_REGISTER_EMAIL, CYPRESS_APP_EMAIL, CYPRESS_LOGIN_EMAIL, CYPRESS_USER_MANAGEMENT_EMAIL]

function containsRestrictedEmail(value) {
  if (typeof value === 'string') {
    return restrictedEmails.includes(value)
  }

  if (Array.isArray(value)) {
    return value.some((val) => containsRestrictedEmail(val))
  }

  if (typeof value === 'object' && value) {
    return Object.values(value).some((val) => containsRestrictedEmail(val))
  }

  return false
}

const handler = {
  get: function (target, prop: string, receiver) {
    const userObject = JSON.parse(localStorage.getItem('data') || '{}')
    if (shouldBlockForUser(userObject) && blockedApiEndpointsForSandbox.includes(prop)) {
      throw new Error('This action is prohibited in demo mode.')
    }

    if (prop === 'prototype') {
      return Reflect.get(target, prop, receiver)
    }
    const origMethod = target[prop]

    return async function (...args) {
      const time = Date.now()
      try {
        args = addTableIdToRequest(prop, args)

        const result = await origMethod.apply(this, args)

        updateTableId(prop, result)

        return result
      } catch (error) {
        const xCache = get(error, 'response.headers.x-cache', 'No x-cache header found')
        console.log({ ...error })
        const browser = navigator && navigator.userAgent ? navigator.userAgent : `No browser found`
        const errorTime = Date.now() - time
        posthog.capture('api_error', { error, method: prop, args, browser, xCache, errorTime })
        console.error(error)
        console.trace('TRACE: openApi: ', error)
        const message =
          typeof error.body === 'string'
            ? error.body
            : error.body?.content || error.body?.message || error.body?.error || error.message || 'Something went wrong'

        const userContainsRestrictedEmail = restrictedEmails.includes(userObject.email)
        const argsContainRestrictedEmail = containsRestrictedEmail(args)

        if (!userContainsRestrictedEmail && !argsContainRestrictedEmail) {
          const hasInvalidToken = message?.toString()?.toLowerCase()?.includes('invalid authentication')
          sendSlackNotification({
            title:
              `Error message: \`${message}\`\n` +
              (hasInvalidToken ? `Expired User Token Details: \n` : ``) +
              (hasInvalidToken ? `Expiration time: \`${getTokenExpireFromStorage()}\`\n` : ``) +
              (hasInvalidToken ? `Creation time: \`${getTokenCreationFromStorage()}\`\n` : ``) +
              (hasInvalidToken ? `Current time: \`${new Date().toUTCString()}\`\n` : ``) +
              `User: \`<https://us.posthog.com/project/26198/person/${encodeURIComponent(userObject.email)}|${userObject.email}>\`\n` +
              `Page URL: \`<${window.location.href}|${window.location.pathname}>\`\n` +
              `API URL: \`${get(error, 'url', undefined) || get(error, 'config.url', '')}\`\n` +
              `Org: \`${userObject?.org}\`\n` +
              `Access Level: \`${getAccessLevel(userObject)}\``,
            message: `Error occurred while calling API \`${target.name}\`.\`${String(prop)}\``,
            channel: 'frontend-alerts'
          })
          error.message = `Error occurred while calling method ${target.name}.${String(prop)}`
          captureException(error)
        }
        throw new Error(message)
      }
    }
  }
}

const AdminService: typeof TSAdminService = new Proxy(TSAdminService, handler)
const DefaultService: typeof TSDefaultService = new Proxy(TSDefaultService, handler)
const UsersService: typeof TSUsersService = new Proxy(TSUsersService, handler)
const BnamesService: typeof TSBnamesService = new Proxy(TSBnamesService, handler)
const DigitalStoresService: typeof TSDigitalStoresService = new Proxy(TSDigitalStoresService, handler)
const NotificationsService: typeof TSNotificationsService = new Proxy(TSNotificationsService, handler)
const StoreAvailabilityService: typeof TSStoreAvailabilityService = new Proxy(TSStoreAvailabilityService, handler)
const ReportsService: typeof TSReportsService = new Proxy(TSReportsService, handler)
const OwnersService: typeof TSOwnersService = new Proxy(TSOwnersService, handler)
const ModuleService: typeof TSModuleService = new Proxy(TSModuleService, handler)

export * from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService'
export {
  AdminService,
  BnamesService,
  DefaultService,
  DigitalStoresService,
  ModuleService,
  NotificationsService,
  OwnersService,
  ReportsService,
  StoreAvailabilityService,
  UsersService
}
